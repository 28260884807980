import { buttonInterface, portfolioGridItemInterface } from "Interfaces";
import { Link } from "react-router-dom";
import PortfolioGrid from "PortfolioGrid";
import Button from "Button";
import React from "react";
import TopMainSection from "TopMainSection";

const projectItems: Array<portfolioGridItemInterface> = [
  {
    name: "iam",
    text: "Cloud Computing",
    imageName: "iam/iam-thumbnail.jpg",
    subHeading: "Identity and Access Management",
  },
  {
    name: "chatbot",
    text: "Telecom",
    imageName: "chatbot/chatbot-thumbnail.png",
    subHeading: "Chatbot",
  },
  {
    name: "tobacco",
    text: "Tobacco Industry",
    imageName: "tobacco/tobacco.png",
    subHeading: "App for farmers",
  },
  {
    name: "dealerbit",
    text: "Grupa Dealer BIT",
    imageName: "dealerbit/2.png",
    subHeading: "Dashboard",
  },
  {
    name: "DealerCRM",
    text: "Grupa Dealer CRM",
    imageName: "DealerCRM/ZAJAWKA.png",
    subHeading: "Stock",
  },
  {
    name: "DeskSet",
    text: "DeskSet",
    imageName: "deskset/1.png",
    subHeading: "Brand Book",
  },
  {
    name: "Bawialnia",
    text: "Bawialnia Miła",
    imageName: "bawialnia/1.png",
    subHeading: "Kindergarten Landing Page",
  },

  {
    name: "MegBeauty",
    text: "Meg Beauty",
    imageName: "MegBeauty/1.jpg",
    subHeading: "Beauty salon webpage",
  },
  {
    name: "b2b",
    text: "B2B Conference",
    imageName: "b2b/1.jpg",
    subHeading: "Landing Page",
  },
  {
    name: "WalkieDoggie",
    text: "Walkie Doggie",
    imageName: "doggie/Mockup.jpg",
    subHeading: "Mobile app",
  },
  {
    name: "makesend",
    text: "MakeSend",
    imageName: "makesend/1.png",
    subHeading: "Identity Visualization",
  },
];

const homeButtonConfig: buttonInterface = {
  text: "Read more",
  type: "primary",
};
function Home() {
  return (
    <div className="home-container">
      <TopMainSection></TopMainSection>
      <Link exact="true" to="/about" title="Learn more about Katarzyna Romańska - UX Designer, Mentor, and NN/g Certified Professional">
        <Button
          text={homeButtonConfig.text}
          type={homeButtonConfig.type}
        ></Button>
      </Link>

      <PortfolioGrid items={projectItems}></PortfolioGrid>
    </div>
  );
}
export default Home;
