import Navigation from "./Navigation";
import "./main.scss";
import Home from "Home";
import { navigationItemInterface } from "./Interfaces";
import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Switch, Route, useLocation } from "react-router-dom";
import About from "About";
import ProjectDetail from "ProjectDetail";

const items: Array<navigationItemInterface> = [
  {
    name: "Portfolio",
    url: "/",
  },
  {
    name: "About",
    url: "/about",
  },
];

function App() {
  const location = useLocation(); // Pobieranie aktualnej lokalizacji

  return (
    <div className="container">
      <Navigation items={items} />

      <AnimatePresence mode="wait">
        <Switch location={location} key={location.pathname}>
          <Route path="/about">
            <PageTransition>
              <About />
            </PageTransition>
          </Route>
          <Route exact path="/">
            <PageTransition>
              <Home />
            </PageTransition>
          </Route>
          <Route path="/projects/:projectName/">
            <PageTransition>
              <ProjectDetail />
            </PageTransition>
          </Route>
        </Switch>
      </AnimatePresence>
    </div>
  );
}

const PageTransition: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.2 }}
  >
    {children}
  </motion.div>
);

export default App;
